import React from "react";

import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="skip-link">
        </div>
        
        <div id="wrapper">
          <div id="head">
            <div id="header">
              <div id="navigation">
                <a id="logo" href="/" />
                <div className="region region-search">
                  <div id="block-search-form" className="block block-search">
                    <div className="content">
                      <form
                        action="/imodium-produkte"
                        method="post"
                        id="search-block-form"
                        acceptCharset="UTF-8"
                      >
                        <div>
                          <div id="search_form">
                            <input
                              type="text"
                              name="search_block_form"
                              id="searchword"
                              maxLength="128"
                            />
                            <input
                              type="submit"
                              name="suchen"
                              id="searchsubmit"
                              defaultValue
                            />
                            <input
                              type="hidden"
                              name="form_token"
                              defaultValue="fpRQ2dP7NgBmLR_3_abvt9HZCsr1ssctdnKOS8-vJyo"
                            />
                            <input
                              type="hidden"
                              name="form_id"
                              defaultValue="search_block_form"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="meta-text">
            IMODIUM®.&nbsp;Wirkstoff: Loperamidhydrochlorid. Über Wirkung und mögliche unerwünschte Wirkungen informieren Gebrauchsinformation, Arzt oder Apotheker.
            </div>
                <div className="meta">
                  <a href="/faq-page/">FAQ</a>
                  <a href="/glossar/">Glossar</a>
                  <a href="/expertenrat/">Expertenrat</a>
                  <a href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p9">
                    Kontakt
                  </a>
                </div>
                <div className="main">
                  <ul id="main-menu" className="links inline clearfix">
                    <li className="menu-597 first">
                      <a href="/wie-wirkt-imodium-akut/">Wie wirkt IMODIUM®?</a>
                    </li>
                    <li className="menu-690 active-trail active">
                      <a
                        href="/imodium-produkte/"
                        title
                        className="active-trail active"
                      >
                        Produkte
                      </a>
                    </li>
                    <li className="menu-727">
                      <a href="/fakten-uber-durchfall/">
                        Durchfall - Ursachen &amp; Behandlung
                      </a>
                    </li>
                    <li className="menu-331">
                      <a href="/diagnose-reizdarm/">Diagnose Reizdarm</a>
                    </li>
                    <li className="menu-627">
                      <a href="/reisedurchfall-vorbeugen/">
                        Durchfall auf Reisen
                      </a>
                    </li>
                    <li className="menu-337">
                      <a href="/durchfall-bei-kindern/">
                        Durchfall bei Kindern
                      </a>
                    </li>
                    <li className="menu-615 last">
                      <a href="/infomaterial/" title>
                        Infomaterial
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="content">
            <div className="sidebar">
              <div className="region region-subnavi">
                <div
                  id="block-menu-menu-menu-produkte"
                  className="block block-menu"
                >
                  <h2>Produkte</h2>
                  <div className="content">
                    <ul className="menu">
                      <li className="first last expanded active-trail">
                        <a
                          href="/imodium-produkte/"
                          title
                          className="active-trail active"
                        >
                          Produktübersicht
                        </a>
                        <ul className="menu">
                          <li className="first leaf">
                            <a href="/imodium-akut-schmelztabletten/" title>
                              IMODIUM® akut Schmelztabletten
                            </a>
                          </li>
                          <li className="last leaf">
                            <a href="/imodium-kapseln/" title>
                              IMODIUM® Kapseln
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="region region-sidebar">
                <div id="block-block-20" className="block block-block">
                  <div className="content">
                    <div
                      style={{
                        background:
                          "url(/sites/default/files/120228_imodium_anlan_07.jpg) no-repeat center top #eaf4f5",
                        padding: "50px 18px 20px 18px",
                        "-webkit-text-align": "center",
                        "text-align": "center",
                        color: "#484848"
                      }}
                    >
                      <h4 style={{"font-size":"30px",
                     "line-height":"26px", "margin":"20px 0 10px 0", "font-family": "Open Sans, sans-serif"}}>
                        
                        HABEN SIE
                        <br />
                        FRAGEN?
                      </h4>
                      <div
                        style={{
                          "-webkit-text-align": "left",
                          "text-align": "left",
                          "font-size": "13px",
                          color: "#767575",
                          padding: "0px 0",
                          "line-height": "18px"
                        }}
                      >
                        &nbsp;
                      </div>
                      <div
                        style={{
                          "-webkit-text-align": "left",
                          "text-align": "left",
                          "font-size": "13px",
                          color: "#767575",
                          padding: "0px 0",
                          "line-height": "18px"
                        }}
                      >
                        Wenden Sie sich an unsere Experten.
                      </div>
                      <div
                        style={{
                          "-webkit-text-align": "left",
                          "text-align": "left",
                          "font-size": "13px",
                          color: "#767575",
                          padding: "0px 0",
                          "line-height": "18px"
                        }}
                      >
                        <p>
                          <a
                            className="georgia arrow_01"
                            href="/expertenrat/"
                            target="_self"
                          >
                            Jetzt informieren
                          </a>
                        </p>
                      </div>
                    </div>
                    <p>&nbsp;</p>
                  </div>
                </div>
              </div>
              &nbsp;
            </div>
            <div className="content_c">
              <div className="region region-content">
                <div id="block-system-main" className="block block-system">
                  <div className="content">
                  <h1><div class="field field-name-field-title-text field-type-text field-label-hidden">
                  <div class="field-items">
                  <div class="field-item even">Imodium<sup>®</sup> akut - Produkte</div>
                  </div></div></h1>
                    <div className="view view-produktuebersicht view-id-produktuebersicht view-display-id-page view-dom-id-e18c53c679e4d754344ef8a3b882a200">
                      <div className="view-content">
                        <div className="views-row views-row-1 views-row-odd views-row-first">
                          <div className="views-field views-field-field-link-url">
                            {" "}
                            <div className="field-content">
                              <div className="produkt">
                                <div className="left">
                                  <img
                                    typeof="foaf:Image"
                                    src="/sites/default/files/Imodium_2mg_Tabletten_20stk_201x175.jpg"
                                    width="201"
                                    height="175"
                                  />
                                </div>
                                <div className="right">
                                  <h2>IMODIUM® akut Schmelztabletten</h2>
                                  <p>
                                    Soforthilfe bei akutem Durchfall, die auf
                                    der Zunge zergeht
                                  </p>
                                  <ul>
                                    <li>Schmelzen sofort auf der Zunge</li>
                                    <li>Einfach ohne Wasser einzunehmen</li>
                                  </ul>
                                  <div className="floatright">
                                    <a
                                      className="btn"
                                      href="/imodium-akut-schmelztabletten/"
                                    >
                                      <span className="start"> Details </span>
                                      <span className="end" />
                                    </a>
                                  </div>
                                </div>
                                <div className="clear" />
                              </div>
                              <div className="gray_line" />
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-2 views-row-even views-row-last">
                          <div className="views-field views-field-field-link-url">
                            {" "}
                            <div className="field-content">
                              <div className="produkt">
                                <div className="left">
                                  <img
                                    typeof="foaf:Image"
                                    src="/sites/default/files/Imodium_2mg_Kapseln_20stk_201x175.jpg"
                                    width="201"
                                    height="175"
                                  />
                                </div>
                                <div className="right">
                                  <h2>IMODIUM® Kapseln</h2>
                                  <p>
                                    &nbsp;Das bewährte Mittel gegen akuten
                                    Durchfall
                                  </p>
                                  <ul>
                                    <li>Der Klassiker bei Durchfall</li>
                                    <li>
                                      Seit mehr als&nbsp;40 Jahren bewährt gegen akuten Durchfall
                                    </li>
                                    <li>Leicht zu schluckende Hartkapseln</li>
                                  </ul>
                                  <div className="floatright">
                                    <a className="btn" href="/imodium-kapseln/">
                                      <span className="start"> Details </span>
                                      <span className="end" />
                                    </a>
                                  </div>
                                </div>
                                <div className="clear" />
                              </div>
                              <div className="gray_line" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <div id="footer">
          <div className="wrap">
            <div className="sitemap">
              <div className="col1">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/wie-wirkt-imodium-akut/" title>
                      Wie wirkt IMODIUM®?
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/loperamid/" title>
                      Der Wirkstoff: Loperamid
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last expanded active-trail">
                    <a
                      href="/imodium-produkte/"
                      title
                      className="active-trail active"
                    >
                      Produktübersicht
                    </a>
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/imodium-akut-schmelztabletten/" title>
                          IMODIUM® akut Schmelztabletten
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/imodium-kapseln/" title>
                          IMODIUM® Kapseln
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="col2">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/fakten-uber-durchfall/" title>
                      Fakten über Durchfall
                    </a>
                  </li>
                  <li className="expanded">
                    <a href="/tipps-bei-durchfall/" title>
                      Tipps bei Durchfall
                    </a>
                    <ul className="menu">
                      <li className="first last leaf">
                        <a href="/tipps-fuer-den-winter/" title>
                          Tipps für den Winter
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="leaf">
                    <a href="/ursachen-von-durchfall/" title>
                      Ursachen von Durchfall
                    </a>
                  </li>
                  <li className="expanded">
                    <a href="/richtig-essen/" title>
                      Gesunde Essgewohnheiten
                    </a>
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/nahrungsmittelallergie/" title>
                          Nahrungsmittelallergien und -unverträglichkeiten
                        </a>
                      </li>
                      <li className="leaf">
                        <a href="/lebensmittelvergiftung/" title>
                          Lebensmittelvergiftung
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/rezepte/" title>
                          Gesunde Rezepte
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="expanded">
                    <a href="/stress-durchfall/" title>
                      Stress und Durchfall
                    </a>
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/hohe-arbeitsbelastung/" title>
                          Hohe Arbeitsbelastung
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/prufungssituationen/" title>
                          Prüfungssituationen
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="leaf">
                    <a href="/chronischer-durchfall/" title>
                      Chronischer Durchfall
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/gastroenteritis/" title>
                      Magen-Darm-Grippe
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/norovirus/" title>
                      Norovirus
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/durchfall-wahrend-ihrer-periode/" title>
                      Durchfall während Ihrer Periode
                    </a>
                  </li>
                </ul>{" "}
              </div>
              <div className="col3">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/diagnose-reizdarm/" title>
                      Diagnose Reizdarm (IBS)
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/behandlung-eines-reizdarms/" title>
                      Behandlung eines Reizdarms
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/reizdarm-ernahrung/" title>
                      Ernährung bei Reizdarm
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/reisedurchfall-vorbeugen/" title>
                      Vorbeugung von Reisedurchfall
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/reiseapotheke/" title>
                      Reiseapotheke
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/reisedurchfall-risikofaktoren/" title>
                      Reisedurchfall Risikofaktoren
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/reisedurchfall-infos-und-fakten/" title>
                      Infos &amp; Fakten zu Reisedurchfall
                    </a>
                  </li>
                </ul>{" "}
              </div>
              <div className="col4">
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/durchfall-bei-kindern/" title>
                      Durchfall bei Kindern
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/infomaterial/" title>
                      Infomaterial
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/faq-page/" title>
                      FAQ
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/glossar/" title>
                      Glossar
                    </a>
                  </li>
                </ul>{" "}
              </div>
              <div className="clear" />
            </div>
            <div className="subnavi">
              <div className="region region-metanavi">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/impressum/" title>
                      Impressum
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/kontakt/" title>
                      Kontakt
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/rechtliche-hinweise/" title>
                      Nutzungsbedingungen
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/datenschutz/" title>
                      Datenschutz
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/cookie-richtlinien/" title>
                      Cookie-Richtlinien
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <Footer></Footer>
        <div id="disclaimer">
          <div className="wrap">
            <div className="region region-copyright">
              <div id="block-block-1" className="block block-block">
                <div className="content">
                   <p>
                     IMODIUM®. Wirkstoff: Loperamidhydrochlorid. Über Wirkung und mögliche unerwünschte Wirkungen informieren Gebrauchsinformation, Arzt oder Apotheker.
                  </p>
                  <p>
                    <br />© Johnson &amp; Johnson GmbH 2011-2019. Diese Website
                    wird von der Johnson &amp; Johnson GmbH veröffentlicht, die
                    allein für deren Inhalte verantwortlich ist. Sie ist für
                    Besucher aus Österreich bestimmt.
                    <br />
                    Letzte Aktualisierung am: 23. Dezember 2021, AT-IM-2100045
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="externlink">
          <div className="close modalClose" />
          <div className="hl">
            Sie verlassen jetzt
            <br />
            unsere Webseite
          </div>
          <p>
            Bitte beachten Sie, dass dieser Link eine Website öffnet, für deren
            Inhalt wir nicht verantwortlich sind und auf die unsere{" "}
            <a href="/datenschutz/">Datenschutzbestimmungen</a> keine Anwendung
            finden.
          </p>
          <div>
            <br />
            <a id="externlink_close" className="btn modalClose" href="#">
              <span className="start">Abbrechen</span>
              <span className="end" />
            </a>
            <a id="externlink_next" className="btn" href="#">
              <span className="start">Weiter</span>
              <span className="end" />
            </a>
            <div className="clear" />
          </div>
        </div>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n<!--//--><!--[CDATA[//--><!--\nfunction euCookieComplianceLoadScripts() {}\n//--><!--]]-->\n"
          }}
        />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              '\n<!--//--><!--[CDATA[//--><!--\nvar eu_cookie_compliance_cookie_name = "";\n//--><!--]]-->\n'
          }}
        />
        <script
          type="text/javascript"
          src="/sites/all/modules/contrib/eu_cookie_compliance/js/eu_cookie_compliance.js?qbpk52"
        />
      </div>
    );
  }
}

export default Page;
